import classNames from "classnames";
import React from "react";

function Divider(props) {
  return (
    <hr
      className={classNames("dash-line", props.inputClassName)}
      style={Object.assign(
        {
          height: 1,
          width: props.width || "100%",
          border: 0,
        },
        props.style
      )}
    />
  );
}

export default Divider;
