import React, { Component } from "react";
import { handleError } from "../lib/errorFunctions";
import { VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND } from "../resources/images";
import { refreshApp } from "../lib/appFunctions";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };

    this.refresh = this.refresh.bind(this);
  }

  static getDerivedStateFromError = (error) => {
    handleError(error);
    return { hasError: true };
  };

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
    handleError(err);
  }

  render() {
    return this.renderErrorWrapper();
  }

  renderErrorWrapper() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center w-screen h-screen bg-white">
          <img
            alt=""
            className="error-image"
            src={VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND}
          />

          <div className="error-state-text font-size-24 font-weight-500 mt-5">
            Oh no!
          </div>

          <div className="error-state-text">Looks like an error occurred.</div>

          <div
            className="vimcal-button font-size-14-important"
            onClick={this.refresh}
          >
            Go back home
          </div>
        </div>
      );
    }

    return this.props.children;
  }

  refresh() {
    refreshApp();
  }
}

export default ErrorBoundary;
