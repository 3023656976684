import React from "react";

export default function ContentTypeLink({ body }) {
  const src = body?.src;
  const label = body?.label ?? "";
  if (!src) {
    return null;
  }
  return (
    <a href={src} className="content-type-link">
      {label}
    </a>
  );
}
