import classNames from "classnames";
import React from "react";

export default function ContentBodyText({ body }) {
  const text = body?.text ?? "";
  const isSubheader = body?.isSubheader;
  return (
    <div
      className={classNames(
        "content-text-body default-body-section-margin-top",
        isSubheader ? "subheader-text" : ""
      )}
    >
      {text}
    </div>
  );
}
