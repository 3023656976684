import React from "react";
import ImageComponent from "../imageComponent";

export default function ContentBodyImage({ body }) {
  const src = body?.src ?? "";
  if (!src) {
    return null;
  }
  return (
    <ImageComponent src={src} alt={""} />
  );
}
