import React from "react";
import { isMobile } from "../../lib/appFunctions";

export default function ContentTypeVideo({ body }) {
  // right now only supports video
  const src = body?.src;
  if (!src) {
    return null;
  }
  return (
    <iframe
      width={isMobile() ? "" : "560"}
      height={isMobile() ? "" : "560"}
      src={src}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      className="default-body-section-margin-top"
      allowFullScreen
    ></iframe>
  );
}
