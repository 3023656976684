import React from "react";
import { Search } from "react-feather";
import { useAppStateStore } from "../stores/appStateStore";
import classNames from "classnames";

export default function SearchInputButton({ inputClassname }) {
  const setIsSearchModeOn = useAppStateStore(
    (state) => state.setIsSearchModeOn
  );

  return (
    <div
      className={classNames(
        "flex items-center search-button mt-6 font-weight-400 select-none cursor-pointer duration-200 width-250px",
        inputClassname || ""
      )}
      onClick={() => setIsSearchModeOn(true)}
    >
      <Search size={14} />
      <div className="ml-2 font-size-14 secondary-text-color">Search docs</div>
    </div>
  );
}
