import React from "react";
import LazyLoad from "react-lazyload";

const ImageComponent = ({ src, alt, aspectRatio }) => {
  const paddingBottom = `${(1 / aspectRatio) * 100}%`;

  return (
    <LazyLoad
      height={400}
      offset={100}
      placeholder={
        <div
          className="image-container default-body-section-margin-top"
          style={{ paddingBottom }}
        ></div>
      }
    >
      <div
        className="image-container default-body-section-margin-top"
        style={{ paddingBottom }}
      >
        <img src={src} alt={alt} />
      </div>
    </LazyLoad>
  );
};

export default ImageComponent;
