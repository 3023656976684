import React, { Suspense } from "react";
import DefaultLoadingScreen from "../defaultLoadingScreen";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ContentBody from "./contentBody";
import { CONTENT_PATH } from "../../data/content";
import SideBar from "../sideBar";

export default function Content() {
  return (
    <Suspense fallback={<DefaultLoadingScreen />}>
      <Router>
        <SideBar inputClassname={"desktop-content"}/>
        <Suspense fallback={<DefaultLoadingScreen />}>
          <Routes>
            {Object.keys(CONTENT_PATH).map((key) => {
              const path = CONTENT_PATH[key];
              return (
                <Route
                  path={`/${path}`}
                  element={<ContentBody path={path} contentKey={key} />}
                  key={key}
                />
              );
            })}
            <Route
              path="*"
              element={
                <ContentBody contentKey={CONTENT_PATH.VIMCAL_BIG_THREE} />
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </Suspense>
  );
}
