import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./js/styles/tailwind.css";
import "./index.css";
import "./js/styles/default-styles.css";
import "./js/styles/styles.css";
import "./js/styles/mobile.css";
import "./js/styles/dark-mode.css";
import "./js/styles/scrollbar.css";
import "./js/styles/media-queries.css"
import "./js/styles/override.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./js/components/errorBoundary";
import DefaultLoadingScreen from "./js/components/defaultLoadingScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Suspense fallback={<DefaultLoadingScreen />}>
      <App />
    </Suspense>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
