import React from "react";
import classNames from "classnames";
import DocsHeader from "./docsHeader";
import ThemeSwitch from "./themeSwitch";
import { Menu, Search, X } from "react-feather";
import { useAppStateStore } from "../stores/appStateStore";
import Divider from "./divider";
import { getSubSectionName } from "../lib/contentAccessor";

export default function MobileHeader({ inputClassname, subSectionContent }) {
  const setIsSearchModeOn = useAppStateStore(
    (state) => state.setIsSearchModeOn
  );
  const setIsMobileSidePanelShowing = useAppStateStore(
    (state) => state.setIsMobileSidePanelShowing
  );
  const isMobileSidePanelShowing = useAppStateStore(
    (state) => state.isMobileSidePanelShowing
  );
  return (
    <div className="mb-10 mobile-header">
      <div
        className={classNames(
          "flex items-center shrink-0",
          "icon-theme-switch-mobile-view",
          "justify-between",
          inputClassname || ""
        )}
      >
        <DocsHeader />
        <div className="flex gap-4 items-center">
          <ThemeSwitch />
          <Search
            size={20}
            className="hoverable-icon"
            onClick={() => {
              setIsSearchModeOn(true);
            }}
          />
        </div>
      </div>

      <div className="flex justify-between px-10 mb-5">
        <div className="font-size-18 font-weight-400 secondary-text-color select-none">
          {getSubSectionName(subSectionContent)}
        </div>
        {isMobileSidePanelShowing ? (
          <X
            size={20}
            className="hoverable-icon"
            onClick={() => {
              setIsMobileSidePanelShowing(false);
            }}
          />
        ) : (
          <Menu
            size={20}
            className="hoverable-icon"
            onClick={() => {
              setIsMobileSidePanelShowing(!isMobileSidePanelShowing);
            }}
          />
        )}
      </div>
      <Divider />
    </div>
  );
}
