import React from "react";
import DocsHeader from "./docsHeader";
import ThemeSwitch from "./themeSwitch";
import classNames from "classnames";

export default function IconWithThemeSwitch({inputClassname}) {
  return (
    <div className={classNames("flex items-center shrink-0", inputClassname || "")}>
      <DocsHeader />
      <ThemeSwitch />
    </div>
  );
}
