import React, { useEffect, useMemo, useRef, useState } from "react";
import { CONTENT_TYPE, getSubSectionContentFromPath } from "../../data/content";
import {
  getBodyContentType,
  getSubSectionName,
  getSubsectionTopicID,
  getTopicBodies,
  getTopicFromSubSection,
  getTopicHeader,
  isTopicHeaderBigHeader,
  shouldHideChapter,
} from "../../lib/contentAccessor";
import ContentBodyText from "./contentBodyText";
import ContentTypeVideo from "./contentTypeVideo";
import ContentTypeLink from "./contentTypeLink";
import ContentTypeList from "./contentTypeList";
import ContentBodyImage from "./contentBodyImage";
import classNames from "classnames";
import { useAppStateStore } from "../../stores/appStateStore";
import SearchOverlayInput from "../search/searchOverlayInput";
import ContentSectionScroller from "./contentSectionScroller";
import MobileHeader from "../mobileHeader";
import MobileSidePanelOverlay from "../mobileSidePanelOverlay";
import { SECOND_IN_MS } from "../../lib/appFunctions";
import { useLocation } from "react-router-dom";
import { Link2 } from "react-feather";
import { getHomeURL } from "../../lib/urlFunctions";
import { useIsMounted } from "../../hooks/useIsMounted";
import { useIntersectionObserver } from "../../hooks/useInteractionObserver";

export const SCROLL_DIRECTION = {
  DOWN: "down",
  UP: "up",
};

export default function ContentBody({ path }) {
  const subSectionContent = useMemo(
    () => getSubSectionContentFromPath(path),
    [path]
  );
  const location = useLocation();
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION.DOWN);
  const scrollRef = useRef(SCROLL_DIRECTION.DOWN);
  const subsectionName = getSubSectionName(subSectionContent);
  const topics = useMemo(
    () => getTopicFromSubSection(subSectionContent),
    [subSectionContent]
  );
  const isSearchModeOn = useAppStateStore((state) => state.isSearchModeOn);
  const isMobileSidePanelShowing = useAppStateStore(
    (state) => state.isMobileSidePanelShowing
  );
  const [activeSection, setActiveSection] = useState("");
  const subSectionTitleRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const observer = useIntersectionObserver({
    setActiveSection,
    scrollRef,
    topics,
  });

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let lastScrollY = scrollContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollY = scrollContainer.scrollTop;
      if (currentScrollY > lastScrollY) {
        setScrollDirection(SCROLL_DIRECTION.DOWN);
        scrollRef.current = SCROLL_DIRECTION.DOWN;
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection(SCROLL_DIRECTION.UP);
        scrollRef.current = SCROLL_DIRECTION.UP;
      }
      lastScrollY = currentScrollY;
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (observer.current) {
      // disconnect from previous observer
      observer.current.disconnect();
    }
    const elements = document.querySelectorAll(".scroll-section"); // Assuming sections have a class "scroll-section"
    elements.forEach((element) => {
      observer.current.observe(element);
    });

    // can't just get ?.[0] because some topics' first element does not have a header
    const firstTopic = topics?.find((topic) => getSubsectionTopicID(topic));
    if (firstTopic) {
      setActiveSection(getSubsectionTopicID(firstTopic));
    }

    const hash = window.location.hash;
    if (hash) {
      const elementId = hash.substring(1); // Remove the '#' from the hash
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      const param = {
        behavior: "auto",
        block: "nearest",
      };
      subSectionTitleRef?.current?.scrollIntoView(param);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [path, topics, location.hash, observer]);

  return (
    <div className={classNames("content-container")} ref={scrollContainerRef}>
      {isSearchModeOn ? <SearchOverlayInput /> : null}
      <MobileHeader subSectionContent={subSectionContent} />
      {isMobileSidePanelShowing ? <MobileSidePanelOverlay /> : null}
      <div className="content-text-width-container">
        <div className="subsection-content-title" ref={subSectionTitleRef}>
          {subsectionName}
        </div>
        {topics.map((topic) => {
          return (
            <RenderContentSection
              key={`${getTopicHeader(topic)}`}
              topic={topic}
              id={getSubsectionTopicID(topic)}
            />
          );
        })}
        <div className="h-10"></div>
      </div>

      <ContentSectionScroller
        topics={topics}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        shouldHide={shouldHideChapter(subSectionContent)}
      />
    </div>
  );
}

function RenderContentSection({ topic, id }) {
  const location = useLocation();
  const [showCopied, setShowCopied] = useState(false);
  const componentIsMounted = useIsMounted();

  return (
    <div id={id} className="scroll-section">
      {getTopicHeader(topic) ? (
        <div
          className={classNames(
            "topic-header",
            isTopicHeaderBigHeader(topic) ? "topic-big-header" : "",
            "flex items-center gap-4",
            "hoverable-container"
          )}
        >
          {showCopied ? (
            <div className="font-size-12 -margin-left-60px w-11">Copied!</div>
          ) : (
            <Link2
              size={16}
              className="hoverable-icon -ml-9 reveal-on-hover w-5"
              onClick={() => {
                setShowCopied(true);
                setTimeout(() => {
                  if (!componentIsMounted.current) {
                    return;
                  }
                  setShowCopied(false);
                }, 1.5 * SECOND_IN_MS);
                navigator.clipboard.writeText(
                  `${getHomeURL()}${location.pathname}#${id}`
                );
              }}
            />
          )}
          <div>{getTopicHeader(topic)}</div>
        </div>
      ) : null}

      {getTopicBodies(topic).map((body, index) => {
        return (
          <div
            key={`${getTopicHeader}_body_${index}`}
            className={classNames(
              getBodyContentType(body) === CONTENT_TYPE.LINK
                ? "default-body-section-margin-top"
                : ""
            )}
          >
            {RenderBody(body)}
          </div>
        );
      })}
    </div>
  );
}

function RenderBody(body) {
  const contentType = getBodyContentType(body);
  switch (contentType) {
    case CONTENT_TYPE.VIDEO:
      return <ContentTypeVideo body={body} />;
    case CONTENT_TYPE.IMAGE:
      return <ContentBodyImage body={body} />;
    case CONTENT_TYPE.LINK:
      return <ContentTypeLink body={body} />;
    case CONTENT_TYPE.LIST:
      return <ContentTypeList body={body} />;
    default:
      // CONTENT_TYPE.TEXT also goes into here.
      return <ContentBodyText body={body} />;
  }
}
