import React, { useEffect, useMemo, useState } from "react";
import { useAppStateStore } from "../../stores/appStateStore";
import Fuse from "fuse.js";
import { SECTIONS } from "../../data/content";
import {
  getSubSectionsFromSection,
  getSubsectionPath,
  getSubsectionTopicID,
  getTopicFromSubSection,
  getTopicHeader,
} from "../../lib/contentAccessor";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { MOUSETRAP_KEYS, isEscapeKey } from "../../lib/keyFunctions";
import * as Mousetrap from "mousetrap";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

export default function SearchOverlayInput() {
  const setIsSearchModeOn = useAppStateStore(
    (state) => state.setIsSearchModeOn
  );
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    Mousetrap.bind(MOUSETRAP_KEYS.ESCAPE, () => {
      setIsSearchModeOn(false);
    });
    return () => {
      Mousetrap.unbind(MOUSETRAP_KEYS.ESCAPE);
    };
  }, []);

  const allTopics = useMemo(() => {
    let topics = [];
    SECTIONS.forEach((headerSection) => {
      const subsections = getSubSectionsFromSection(headerSection);
      subsections.forEach((subSection) => {
        topics = topics.concat(getTopicFromSubSection(subSection));
      });
    });
    return topics;
  }, []);

  // legacy -=>
  // const allSections = useMemo(() => {
  //   let allSections = [];
  //   SECTIONS.forEach((headerSection) => {
  //     allSections = allSections.concat(
  //       getSubSectionsFromSection(headerSection)
  //     );
  //   });
  //   return allSections;
  // }, []);

  // const options = {
  //   includeScore: true,
  //   keys: [
  //     "name",
  //     "path",
  //     "topics.header",
  //     "topics.bodies.text",
  //     "topics.bodies.items.text",
  //     "topics.bodies.label",
  //   ],
  // };
  const options = {
    includeScore: true,
    keys: [
      "path",
      "header",
      "bodies.text",
      "bodies.items.text",
      "bodies.label",
    ],
  };
  const fuse = new Fuse(allTopics, options);

  const onChange = (e) => {
    const searchInput = getInputStringFromEvent(e);
    setSearchInput(searchInput);
    const results = fuse.search(searchInput);
    setResults(results);
  };

  const onKeyDown = (e) => {
    if (isEscapeKey(e)) {
      setIsSearchModeOn(false);
    }
  };

  const renderInput = () => {
    return (
      <input
        type="text"
        placeholder="Search docs"
        className={classNames(
          "search-input",
          isEmptyArray(results) ? "rounded-md" : ""
        )}
        value={searchInput}
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
        autoFocus
        onKeyDown={onKeyDown}
      />
    );
  };

  const renderResults = () => {
    const filteredResults = results.filter((result) => getTopicHeader(result.item));
    if (isEmptyArray(filteredResults)) {
      return null;
    }
    return (
      <div className="search-result-container">
        {filteredResults.map((result, index) => {
          const { item } = result;
          const path = getSubsectionPath(item);
          return (
            <div
              className=""
              key={`results-parent-container-${getTopicHeader(item)}-${path}-${index}`}
            >
              <div
                key={`search-result-${getTopicHeader(item)}`}
                className="individual-search-result"
                onClick={() => {
                  const pathAndElementID = `${path}#${getSubsectionTopicID(item)}`;
                  navigate(`/${pathAndElementID}`);
                }}
              >
                {getTopicHeader(item)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="search-overlay" onClick={() => setIsSearchModeOn(false)}>
      <div
        className="absolute h-80 max-h-80 search-input-result-container"
        style={{
          inset: "200px auto auto 50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {renderInput()}
        {renderResults()}
      </div>
    </div>
  );
}
