import classNames from "classnames";
import React from "react";
import { scrollToSection } from "../../lib/appFunctions";
import {
  getSubsectionTopicID,
  getTopicHeader,
} from "../../lib/contentAccessor";
import { isEmptyArray, isLastElement } from "../../lib/arrayFunctions";

export default function ContentSectionScroller({
  topics,
  activeSection,
  setActiveSection,
  shouldHide,
}) {
  const filteredContent = topics.filter((topic) => getTopicHeader(topic));
  if (shouldHide || isEmptyArray(filteredContent) || filteredContent.length === 1) {
    // so the section does not move
    return <div className="content-scroller-menu"></div>;
  }

  return (
    <div
      className={classNames(
        "sticky top-7 right-5 select-none h-max content-scroller-menu",
        "flex flex-col",
        "justify-start",
        "shrink-0"
      )}
    >
      <div className="font-weight-500 font-size-16 mb-4">On this page</div>
      {topics.map((topic, index) => {
        const header = getTopicHeader(topic);
        if (!header) {
          return null;
        }
        const id = getSubsectionTopicID(topic);
        return (
          <div
            key={`content-section-scroller-${header}`}
            className={classNames(
              "flex items-center cursor-pointer duration-200",
              "h-9 w-44"
            )}
            onClick={() => {
              setActiveSection(id);
              scrollToSection(id);
            }}
          >
            <div
              className={classNames(
                "w-1 h-full",
                "flex-shrink-0",
                activeSection === id
                  ? "side-bar-scroller-matched rounded-sm"
                  : "side-bar-scroller-unmatched",
                "mr-2",
                index === 0 ? "rounded-t-sm" : "",
                isLastElement(index, topics) ? "rounded-b-sm" : ""
              )}
            ></div>
            <div
              className={classNames(
                "truncate-text default-font-size font-weight-400",
                activeSection === id ? "" : "hoverable-secondary-text-color"
              )}
            >
              {header}
            </div>
          </div>
        );
      })}
    </div>
  );
}
