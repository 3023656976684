import create from "zustand";
import { persist } from "zustand/middleware";

export const useAppStateStore = create(
  persist(
    (set) => ({
      isDarkMode: true,
      setIsDarkMode: (isDarkMode) => {
        set((state) => ({ isDarkMode }));
      },
      resetIsDarkMode: (isDarkMode) => {
        set((state) => ({ isDarkMode: true }));
      },
      isSearchModeOn: false,
      setIsSearchModeOn: (isSearchModeOn) => {
        set((state) => ({ isSearchModeOn }));
      },
      isMobileSidePanelShowing: false,
      setIsMobileSidePanelShowing: (isMobileSidePanelShowing) => {
        set((state) => ({ isMobileSidePanelShowing }));
      },
    }),
    {
      name: "app-state-store", // unique name
    }
  )
);
