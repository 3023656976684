// using event.key
export const KEY_CONSTS = {
  ESCAPE: "Escape",
};

export const MOUSETRAP_KEYS = {
  ESCAPE: "esc",
};

export function isEscapeKey(e) {
  return e.key === KEY_CONSTS.ESCAPE;
}
