export const THEME = {
  DARK: "dark",
  LIGHT: "light",
};

let _isSafari;
export const SECOND_IN_MS = 1000;

export function updateTheme(theme) {
  document.documentElement.setAttribute("data-theme", theme);
}

export function refreshApp() {
  window.location.reload(true);
}

export function isMobile() {
  if (!window?.innerWidth) {
    return false;
  }

  return window.innerWidth <= 600;
}

export function isSafari() {
  if (isNullOrUndefined(_isSafari)) {
    const userAgent = navigator.userAgent;
    const isBrowserSafari =
      /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    _isSafari = isBrowserSafari;
    return isBrowserSafari;
  }

  return _isSafari;
}

export function isNullOrUndefined(value) {
  return isNull(value) || isUndefined(value);
}

export function isUndefined(value) {
  return typeof value === "undefined";
}

export function isNull(value) {
  return value === null;
}

export function scrollToSection(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}
export function OpenLink(url) {
  window.open(url, "_blank");
}

export function openLinkOnSamePage(url) {
  window.open(url, "_self");
}

export function guardedPreventDefault(e) {
  if (e?.preventDefault) {
    e?.preventDefault();
  }
}

export function guardedStopPropagation(e) {
  if (e?.stopPropagation) {
    e?.stopPropagation();
  }
}
