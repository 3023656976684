import { CONTENT_TYPE } from "../data/content";

export function getSectionName(section) {
  return section?.name ?? "";
}

export function getSubsectionPath(section) {
  return section?.path ?? "";
}

export function getSubSectionsFromSection(section) {
  return section?.sections ?? [];
}

export function getSubSectionName(subSection) {
  return subSection?.name ?? "";
}

export function getTopicFromSubSection(subSection) {
  const topics = subSection?.topics ?? [];
  return topics.map((topic) => {
    return {
      ...topic,
      path: getSubsectionPath(subSection),
    };
  });
}

export function getTopicHeader(topic) {
  return topic?.header ?? "";
}

export function isTopicHeaderBigHeader(topic) {
  return topic?.isBigHeader ?? false;
}

export function getTopicBodies(topic) {
  return topic?.bodies ?? [];
}

export function getBodyContentType(body) {
  return body?.type ?? CONTENT_TYPE.TEXT;
}

export function getSubsectionTopicID(topic) {
  const topicHeader = getTopicHeader(topic);
  return topicHeader.toLowerCase().replace(/ /g, '-');
}

export function shouldHideChapter(subSection) {
  return subSection?.hideChapters ?? false;
}
