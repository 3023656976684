import "./App.css";
import { isMobile, isSafari } from "./js/lib/appFunctions";
import classNames from "classnames";
import Content from "./js/components/content";

// controls theme (dark mode/light mode)
function App() {
  return (
    <div
      className={classNames(
        "app-wrapper",
        isMobile() ? "" : "flex",
        isSafari() ? "" : "home-page-hide-scroll-bar-unless-hover"
      )}
    >
      <Content />
    </div>
  );
}

export default App;
