import React, { useEffect } from "react";
import { useAppStateStore } from "../stores/appStateStore";
import { Moon, Sun } from "react-feather";
import classNames from "classnames";
import {
  THEME,
  guardedPreventDefault,
  guardedStopPropagation,
  updateTheme,
} from "../lib/appFunctions";

const SIZE = 16;
const ICON_CLASSNAME = "w-5 h-5 items-center justify-center flex z-1";
export default function ThemeSwitch() {
  const isDarkMode = useAppStateStore((state) => state.isDarkMode);
  const setIsDarkMode = useAppStateStore((state) => state.setIsDarkMode);
  useEffect(() => {
    if (isDarkMode) {
      updateTheme(THEME.DARK);
    } else {
      updateTheme(THEME.LIGHT);
    }
  }, []);

  const toggleTheme = (e) => {
    guardedPreventDefault(e);
    guardedStopPropagation(e);
    const updatedIsDark = !isDarkMode;
    setIsDarkMode(updatedIsDark);
    if (updatedIsDark) {
      updateTheme(THEME.DARK);
    } else {
      updateTheme(THEME.LIGHT);
    }
  };

  const getSunColor = () => {
    return isDarkMode ? "#9396A0" : "#DBBE8D";
  };
  const getMoonColor = () => {
    return isDarkMode ? "#4C79ED" : "#A1A2AD";
  };
  return (
    <div className="theme-selector-container relative" onClick={toggleTheme}>
      <div
        className={classNames("selected-theme-background", "duration-200")}
      ></div>
      <div className={classNames(ICON_CLASSNAME)}>
        <Moon
          size={SIZE}
          fill={getMoonColor()}
          color={getMoonColor()}
          className="theme-moon duration-200"
        />
      </div>

      <div className={ICON_CLASSNAME}>
        <Sun
          size={SIZE}
          fill={getSunColor()}
          color={getSunColor()}
          className="theme-sun duration-200"
        />
      </div>
    </div>
  );
}
