import { useEffect, useRef } from "react";
import { getSubsectionTopicID } from "../lib/contentAccessor";
import { SCROLL_DIRECTION } from "../components/content/contentBody";

export const useIntersectionObserver = ({ setActiveSection, scrollRef, topics }) => {
  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        const viewportHeight = window.innerHeight;
        const entryBoundingClientRect = entry.boundingClientRect;
        const top = entryBoundingClientRect.top;

        const { id } = entry.target;
        const topicIDs = topics.map((topic) => getSubsectionTopicID(topic));
        // can't pass in topicIDs because it's newly mapped on every render
        const currentElementIndex = topicIDs.indexOf(id);
        // const shouldPrint = false;
        // shouldPrint && console.log("top_", top);
        // shouldPrint && console.log("viewportHeight", viewportHeight);
        // shouldPrint && console.log("entry.intersectionRatio", entry.intersectionRatio, top / viewportHeight);
        if (
          (scrollRef.current === SCROLL_DIRECTION.DOWN &&
            top < viewportHeight / 2) ||
          (scrollRef.current === SCROLL_DIRECTION.UP &&
            top > viewportHeight / 5)
        ) {
          setActiveSection((prevActiveElement) => {
            const prevIndex = topicIDs.indexOf(prevActiveElement);
            if (
              (scrollRef.current === SCROLL_DIRECTION.DOWN &&
                currentElementIndex > prevIndex) ||
              (scrollRef.current === SCROLL_DIRECTION.UP &&
                currentElementIndex < prevIndex)
            ) {
              return id;
            }
            return prevActiveElement;
          });
        }
      },
      {
        root: null,
        // rootMargin: "0px",
        // threshold: Array.from({ length: 100 }, (_, i) => i / 100),
        rootMargin: "-50% 0px -50% 0px", // new
        threshold: 0,
      }
    );

    return () => observer.current.disconnect();
  }, [setActiveSection, scrollRef, topics]);

  return observer;
};
