import React from "react";
import { useAppStateStore } from "../stores/appStateStore";
import SideBar from "./sideBar";

export default function MobileSidePanelOverlay() {
  const setIsMobileSidePanelShowing = useAppStateStore(
    (state) => state.setIsMobileSidePanelShowing
  );

  return (
    <div
      className="mobile-side-panel-overlay mobile-content"
      onClick={() => setIsMobileSidePanelShowing(false)}
    >
      <SideBar
        inputClassname={"mobile-content flex-col"}
        hideIconAndThemeSwitch={true}
        hideSearch={true}
      />
    </div>
  );
}
