import React from "react";
import { SECTIONS } from "../../data/content";
import {
  getSectionName,
  getSubsectionPath,
  getSubSectionName,
  getSubSectionsFromSection,
} from "../../lib/contentAccessor";
import { useNavigate } from "react-router-dom";
import SearchInputButton from "../searchInputButton";
import IconWithThemeSwitch from "../iconWithThemeSwitch";
import classNames from "classnames";

export default function SideBar({
  inputClassname,
  hideIconAndThemeSwitch = false,
  hideSearch = false,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <div className={classNames("p-6 side-bar-wrapper", inputClassname || "")}>
        {hideIconAndThemeSwitch ? null : <IconWithThemeSwitch inputClassname={"justify-between width-250px"} />}
        {hideSearch ? null : <SearchInputButton inputClassname={"mb-6"} />}
        {SECTIONS.map((section, index) => {
          return (
            <div
              key={`section-header-${getSectionName(section)}`}
              className={classNames("section-label", index === 0 ? "margin-top-0px-override" : "")}
            >
              <div className="mb-1 side-bar-headers">{getSectionName(section)}</div>

              {getSubSectionsFromSection(section).map((subSection, index) => {
                return (
                  <div
                    key={`sub-section-${index}`}
                    className="sub-section-label"
                    onClick={() => {
                      navigate(getSubsectionPath(subSection));
                    }}
                  >
                    {getSubSectionName(subSection)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
