export const ENVIRONMENTS = {
  DEV: "dev",
  DOGFOOD: "dogfood",
  LOCAL: "local",
  PRODUCTION: "production",
  STAGING: "staging",
  TESTING: "testing",
};

export function isLocal() {
  if (!process.env.REACT_APP_SERVER_BASE_URL) {
    return true;
  }

  return process.env.REACT_APP_SERVER_BASE_URL === ENVIRONMENTS.LOCAL;
}
