import React from "react";
import { useAppStateStore } from "../stores/appStateStore";
import VimcalLogoWhite from "../logos/vimcalLogoWhite";
import VimcalLogoDarkBlue from "../logos/vimcalLogoDarkBlue";

export default function DocsHeader() {
  const width = 74;
  const isDarkMode = useAppStateStore((state) => state.isDarkMode);
  return (
    <div className="flex items-center">
      {isDarkMode ? (
        <VimcalLogoWhite width={width} />
      ) : (
        <VimcalLogoDarkBlue width={width} />
      )}
      <div className="ml-2 font-weight-400 font-size-16 mr-5">Docs</div>
    </div>
  );
}
