export function isArray(value) {
	return Array.isArray(value);
}

export function isEmptyArray(array) {
  return !array || array.length === 0;
}

export function isLastElement(index, array) {
	return index === array.length - 1;
}
